import { Field, FieldProps } from "formik";
import React from "react";

interface InputBoxProps {
  id: string;
  type: string;
  label?: string;
  name: string;
  autoComplete?: "on" | "off";
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  labelClass?: string;
}

const InputBox: React.FC<InputBoxProps> = ({
  id,
  type,
  label,
  autoComplete,
  name,
  placeholder,
  disabled,
  className,
  labelClass,
}) => {
  return (
    <div>
      {label && (
        <label className={`mb-2.5 block ${labelClass}`} htmlFor={id}>
          {label}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <>
            <input
              autoComplete={autoComplete}
              id={id}
              type={type}
              {...field}
              placeholder={placeholder}
              disabled={disabled}
              className={`${className} w-full border border-grey-100 bg-[#F9F9F9] px-4 py-3 outline-site-blue`}
            />
            {meta.touched && meta.error && (
              <div className="text-red-500">{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default InputBox;
