import { object, string } from "yup";
import { Form, Formik } from "formik";
import InputBox from "../../components/InputBox";
import { errorToast } from "../../helpers/toast.helper";
import { NavLink, useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../enums/Navigation.enum";
import { useDependency } from "../../contexts/DependencyProvider.context";
import { LoginMethodParam } from "../../types/services/authService.type";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const { authService } = useDependency();

  const handleLogin = async (values: LoginMethodParam) => {
    // Redirect to the home page after successful login
    try {
      await authService.login(values);

      navigate(NAVIGATION.HOME);
    } catch (e: any) {
      errorToast(e.message);
    }
    // Perform login logic with email and password
  };

  const loginSchema = object().shape({
    email: string().email("Invalid email").required("Email is required"),
    password: string().required("Password is required"),
  });

  return (
    <div className="mx-4 mt-12 rounded-md  border p-4 px-[40px] py-9 md:mx-auto md:w-[404px]">
      <h1 className="text-left text-4xl font-bold">Login</h1>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          handleLogin(values);
        }}
      >
        {() => (
          <Form>
            <div className="mb-4 mt-3">
              <InputBox
                type="text"
                label="Email"
                name="email"
                id={"email"}
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4">
              <InputBox
                type="password"
                label="Password"
                autoComplete="on"
                id={"password"}
                name="password"
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4">
              <NavLink className="text-sm text-primary" to={NAVIGATION.FORGOT_PASSWORD}>
                Forgot Password?
              </NavLink>
            </div>

            <div className="flex justify-end">
              <input
                type="submit"
                className="w-full cursor-pointer bg-primary px-4 py-2 text-white"
                value={"Login"}
              />
            </div>

            <p className="mt-4 text-sm">
              Don't have an account?{" "}
              <NavLink
                className="text-primary underline"
                to={NAVIGATION.REGISTER}
              >
                Register
              </NavLink>{" "}
              now.
            </p>
          </Form>
        )}
      </Formik>
      <div></div>
    </div>
  );
};

export default LoginPage;
