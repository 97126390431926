import 'react-toastify/dist/ReactToastify.css';
import BadRequestException from "../exceptions/BadRequestException";
import MakingRequestException from "../exceptions/MakingRequestException";
import ServerException from "../exceptions/ServerException";
import UnauthorizedException from "../exceptions/UnauthorizedException";

export function handleServiceError(error: any): void {
  // Implement your error handling logic here
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const statusCode = error.response.status;
    const message = error.response.data.message;

    if (statusCode === 400) {
      throw new BadRequestException(message, 400);
    } else if (statusCode === 500) {
      throw new ServerException(message, 500);
    } else if (statusCode === 405) {
      throw new UnauthorizedException(message, 500);
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser 
    // and an instance of http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new MakingRequestException("An error occured while making an request");
  }
  // Additional error handling code...
}

