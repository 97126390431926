import FileUploadBox from "./FileUploadBox";
import DocumentsPic from "../assets/documents-pic.png";
import Loader from "./Loader";
import { KYCFormUploadSectionProps } from "../types/components/kycFormUploadSection.type";


const KYCFormUploadSection: React.FC<KYCFormUploadSectionProps> = ({
  filesToUpload,
  uploadedFiles,
  onFileUploaded,
  componentLoading,
}) => {

  const filesWithUploadStatus = filesToUpload.map((file) => {
    const matchedFile = uploadedFiles.find(
      (f) => f.type.toString() === file.id.toString()
    );

    return {
      ...file,
      fileUploaded: !!matchedFile,
      ...matchedFile,
      filename: matchedFile ? matchedFile.filename : undefined,
    };
  });

  return (
    <>
      <h1 className="heading-1 mt-24 text-site-text-heading">
        Document Upload
      </h1>

      <div className="mt-5 border border-grey-100 ">
        <header className="flex space-x-4 border-b border-grey-100 bg-[#FAFAFA] p-5">
          <div>
            <img src={DocumentsPic} alt="Info" />
          </div>
          <div>
            <h3 className="heading-3 ">My Documents </h3>
            <p>Please upload them in a supported format (PDF, JPG,PNG,Docx).</p>
          </div>
        </header>

        {componentLoading ? (
          <div className="flex items-center justify-center py-32">
            <Loader />
          </div>
        ) : (
          <div>
            {filesWithUploadStatus.map((file) => (
              <FileUploadBox
                onFileUpload={() => onFileUploaded()}
                key={file.id}
                label={file.label}
                filename={file.filename}
                id={file.id}
                fileUploaded={file.fileUploaded}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default KYCFormUploadSection;
