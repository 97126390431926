import { Navigate } from "react-router-dom";
import TokenHelper from "../helpers/Token.helper";

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
  otherCondition: boolean;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
  otherCondition
}: ProtectedRouteProps) {
  const tokenHelper = new TokenHelper();
  if (tokenHelper.getToken() != "" && otherCondition) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}
