import Loader from "./Loader";
import FileUploadBox from "./FileUploadBox";
import LLCPic from "../assets/office-building.png";
import { LLCSection } from "../types/documentsData.type";
import { KYCFormLLCUploadSectionProps } from "../types/components/kycFormUploadSection.type";

const KYCFormLLCUploadSection: React.FC<KYCFormLLCUploadSectionProps> = ({
  llcDocumentsData,
  uploadedFiles,
  onFileUploaded,
  componentLoading,
}) => {


  const filesWithUploadStatus = Object.values(llcDocumentsData).flatMap(
    (section: LLCSection) =>

      section.documentsToUpload.map((file) => {
        const matchedFile = uploadedFiles.find(
          (f) => f.type.toString() === file.id.toString()
        );

        return {
          ...file,
          fileUploaded: !!matchedFile,
          filename: matchedFile ? matchedFile.filename : undefined,
        };
      })
  );

  return (
    <>
      <div className="mt-5 border border-grey-100 ">
        <header className="flex space-x-4 border-b border-grey-100 bg-[#FAFAFA] p-5">
          <div>
            <img className="h-[40px] w-[40px]" src={LLCPic} alt="Info" />
          </div>
          <div>
            <h3 className="heading-3">For LLC</h3>
            <p>
              Please upload them in a supported format (PDF, JPG, PNG, Docx).
            </p>
          </div>
        </header>
        {Object.values(llcDocumentsData).map((section: LLCSection) => (
          <div key={section.label}>
            <header className="flex space-x-4 border-b border-grey-100 bg-[#FAFAFA] p-5">
              <div>
                <h3 className="heading-3">{section.label}</h3>
              </div>
            </header>
            {componentLoading ? (
              <div className="flex items-center justify-center py-32">
                <Loader />
              </div>
            ) : (
              <div>
                {section.documentsToUpload.map((file) => {
                  const matchedFile = filesWithUploadStatus.find(
                    (f) => f.id === file.id
                  );
                  return (
                    <FileUploadBox
                      onFileUpload={() => onFileUploaded()}
                      key={file.id}
                      label={file.label}
                      filename={matchedFile?.filename}
                      id={file.id}
                      fileUploaded={matchedFile?.fileUploaded}
                    />
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default KYCFormLLCUploadSection;
