import { useEffect, useState } from "react";
import HomePage from "../pages/Home.page";
import KYCForm from "../pages/KYCForm.page";
import TrackingPage from "../pages/Tracking.page";
import ProcessLayout from "./ProcessLayout";
import OnboardingPage from "../pages/Onboarding.page";
import {
  DependencyProvider,
  IServicesDependency,
} from "../contexts/DependencyProvider.context";
import UserService from "../services/User.service";
import ApiClient from "../helpers/ApiClient.helper";

import FileService from "../services/File.service";
import AuthService from "../services/Auth.service";
import LoginPage from "../pages/auth/Login.page";
import RegisterPage from "../pages/auth/Register.page";
import TokenHelper from "../helpers/Token.helper";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "../components/ProtectedRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NAVIGATION } from "../enums/Navigation.enum";
import ForgotPasswordPage from "../pages/auth/ForgotPassword.page";
import ChangePasswordPage from "../pages/auth/ChangePassword.page";
import AuthProtectedRoute, {
  AuthProtectedRouteProps,
} from "../components/AuthProtectedRoutes";
import Header from "../components/Header";
import VerifyEmailPage from "../pages/auth/VerifyEmail.page";

const BaseLayout = () => {
  const [onboardingProgressPercent, setOnboardingProgressPercent] = useState(0);
  const [kycProgressPercent, setKycProgressPercent] = useState(0);
  const [trackingProgressPercent, setTrackingProgressPercent] = useState(0);

  // Enable navigation only when the onboarding page if filled
  const [enableOtherNavigations, setEnableOtherNavigation] = useState(false);

  // Dependency injections
  const tokenHelper = new TokenHelper();
  const [token, setToken] = useState(tokenHelper.getToken());

  const apiClient = new ApiClient({
    accessToken: token == "" ? "QU3czvL1rBbG3q4qBjUh46OMyI7WXU1kSA" : token,
    authApiBase: import.meta.env.VITE_AUTH_BASE_API,
  });

  const userService = new UserService(apiClient);
  const fileService = new FileService(apiClient);
  const authService = new AuthService(apiClient, tokenHelper);

  const services: IServicesDependency = {
    userService: userService,
    fileService: fileService,
    authService: authService,
    tokenHelper: tokenHelper,
  };

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: NAVIGATION.LOGIN,
    otherCondition: true,
  };

  const authProtectedProps: Omit<AuthProtectedRouteProps, "outlet"> = {
    redirectPath: NAVIGATION.HOME,
  };

  // TODO: Stop direct navigation to kyc pages
  // const processProtectdRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
  //   authenticationPath: "/process/onboarding",
  //   token: tokenHelper.getToken(),
  //   otherCondition: enableOtherNavigations,
  // };

  useEffect(() => {
    setEnableOtherNavigation(
      userService.getUserPreferenceFromStorage() != undefined
    );
  }, [userService]);

  useEffect(() => {
    setToken(tokenHelper.getToken());
  }, [tokenHelper]);

  return (
    <DependencyProvider services={services}>
      <BrowserRouter basename="/">
        <div>
          <Header />
        </div>
        {/* Remember Message */}

        <main className="py-4">
          {/* Pages here */}
          <Routes>
            <Route
              index
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<HomePage />}
                />
              }
            />
            <Route
              path={NAVIGATION.VERIFY_EMAIL}
              element={
                <AuthProtectedRoute
                  {...authProtectedProps}
                  outlet={<VerifyEmailPage />}
                />
              }
            />

            <Route
              path={NAVIGATION.LOGIN}
              element={
                <AuthProtectedRoute
                  {...authProtectedProps}
                  outlet={<LoginPage />}
                />
              }
            />
            <Route
              path={NAVIGATION.REGISTER}
              element={
                <AuthProtectedRoute
                  {...authProtectedProps}
                  outlet={<RegisterPage />}
                />
              }
            />
            <Route
              path={NAVIGATION.FORGOT_PASSWORD}
              element={
                <AuthProtectedRoute
                  {...authProtectedProps}
                  outlet={<ForgotPasswordPage />}
                />
              }
            />
            <Route
              path={NAVIGATION.CHANGE_PASSWORD}
              element={
                <AuthProtectedRoute
                  {...authProtectedProps}
                  outlet={<ChangePasswordPage />}
                />
              }
            />

            <Route
              path={NAVIGATION.PROCESS}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <ProcessLayout
                      onboardingProgressPercent={onboardingProgressPercent}
                      kycProgressPercent={kycProgressPercent}
                      trackingProgressPercent={trackingProgressPercent}
                      enableOtherNavigations={enableOtherNavigations}
                    />
                  }
                />
              }
            >
              <Route
                path={NAVIGATION.ONBOARDING}
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={
                      <OnboardingPage
                        onProgressChange={(percent) =>
                          setOnboardingProgressPercent(percent)
                        }
                      />
                    }
                  />
                }
              />

              <Route
                path={NAVIGATION.TRACKING}
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={
                      <TrackingPage
                        onPercentageChange={(number) =>
                          setTrackingProgressPercent(number)
                        }
                      />
                    }
                  />
                }
              />

              <Route
                path={NAVIGATION.KYC}
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={
                      <KYCForm
                        onPercentChange={(percent) =>
                          setKycProgressPercent(percent)
                        }
                      />
                    }
                  />
                }
              />
            </Route>
          </Routes>
        </main>
      </BrowserRouter>
    </DependencyProvider>
  );
};

export default BaseLayout;
