import {
  ICreatePropertiesParams,
  IGetUserPropertiesResponse,
} from "../types/services/userService.type.ts";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Loader from "../components/Loader.tsx";
import InputBox from "../components/InputBox";
import MyInfoPic from "../assets/my-info-pic.png";
import { SUCCESS_MESSAGE } from "../enums/Messages.enum.ts";
import { AnyObject, StringSchema, object, string } from "yup";
import { errorToast, successToast } from "../helpers/toast.helper.ts";
import KYCFormUploadSection from "../components/KYCFormUploadSection";
import { useDependency } from "../contexts/DependencyProvider.context";
import { INDIVIDUAL_LLC_QUESTION } from "../enums/Onbnoarding.enum.ts";
import { documentsFieldData } from "../data/documentFieldsAndFilesData.ts";
import { IGetUserFilesResponse } from "../types/services/fileService.types.ts";
import KYCFormLLCUploadSection from "../components/KYCFormLLCUploadSection.tsx";

interface KYCFormPageProps {
  onPercentChange: (percent: number) => void;
}

const KYCFormPage: React.FC<KYCFormPageProps> = ({ onPercentChange }) => {
  const { userService, fileService } = useDependency();

  const [isProcessing, setIsProcessing] = useState(false);
  const [filesSectionProcessing, setFilesSectionProcessing] = useState(false);
  const [llcFilesSectionProcessing, setLlcFilesSectionProcessing] =
    useState(false);

  const [llcSupport, setLlcSupport] = useState(false);

  const [userUploadedFiles, setUserUploadedFiles] =
    useState<IGetUserFilesResponse>([]);

  const [preSetValues, setPreSetValues] =
    useState<IGetUserPropertiesResponse>();

  // Get user prefs
  const getUserPrefs = () => userService.getUserPreferenceFromStorage();

  // Set if llc files are to be uploaded
  const setShowLlcFiles = () => {
    const prefs = getUserPrefs();

    if (
      prefs?.individualLlcQuestion === INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION
    )
      setLlcSupport(true);
  };

  // Get right document object
  const getDocumentsObject = () => {
    const prefs = getUserPrefs();

    if (!prefs) return;

    const buyerSellerKey = prefs.buyerSellerQuestion;
    const nationality = prefs.nationalityQuestion;
    const llcIndividual =
      prefs.individualLlcQuestion === INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION
        ? INDIVIDUAL_LLC_QUESTION.CORPORATION
        : prefs.individualLlcQuestion;

    return documentsFieldData[buyerSellerKey][llcIndividual][nationality];
  };

  const fieldsData = getDocumentsObject()!.fieldsToFill;

  const filesToUpload = getDocumentsObject()!.documentsToUpload;

  const llcFilesToUpload = documentsFieldData.llc;

  const llcFilesCount =
    documentsFieldData.llc.fromLlcManager.documentsToUpload.length +
    documentsFieldData.llc.fromLlcMembers.documentsToUpload.length +
    documentsFieldData.llc.fromTheLlc.documentsToUpload.length;

  useEffect(() => {
    getUserProperties();
    getUserUploadedFiles();

    setShowLlcFiles();
  }, []);

  // Get data from API to setup in form
  const getUserProperties = async () => {
    setIsProcessing(true);

    const data = await userService.getUserProperties(getFieldKeys());

    setPreSetValues(data);

    setIsProcessing(false);
  };

  const getUserUploadedFiles = async () => {
    const files = await fileService.getUserFiles();

    setUserUploadedFiles(files);
  };

  // Get all the field keys in properties
  const getFieldKeys = () => {
    const properties = {
      properties: [] as string[],
    };

    for (const field of fieldsData) {
      properties.properties.push(field.name);
    }

    return properties;
  };

  // Generate initial values object dynamically
  const initialValues = fieldsData.reduce((acc, field) => {
    (acc as any)[field.name] = preSetValues?.[field.name] || "";

    return acc;
  }, {});

  const calculatePercent = () => {
    const filledFields = Object.values(initialValues).filter(
      (value) => value !== ""
    );
    const percent =
      ((filledFields.length + userUploadedFiles.length) /
        (Object.keys(initialValues).length +
          filesToUpload.length +
          llcFilesCount)) *
      100;

    return Number(percent.toFixed(0));
  };

  // Update percent
  useEffect(() => {
    onPercentChange(calculatePercent());
  }, [initialValues, isProcessing]);

  // Generate validation schema dynamically
  const generateValidationSchema = () => {
    const schema: Record<string, StringSchema<string, AnyObject>> = {};
    for (const field of fieldsData) {
      if (field.validation) {
        schema[field.name] = field.validation;
      } else {
        schema[field.name] = string().required(`${field.label} is required`);
      }
    }
    return object().shape(schema);
  };

  const updateProperties = async (values: ICreatePropertiesParams) => {
    setIsProcessing(true);

    try {
      await userService.updateProperties(values);

      // Refresh to reflect new changes
      getUserProperties();

      successToast(SUCCESS_MESSAGE.FIELDS_UPDATED);
    } catch (e: any) {
      errorToast(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onFileUploadLLCSection = async () => {
    setLlcFilesSectionProcessing(true);

    await getUserUploadedFiles();

    setLlcFilesSectionProcessing(false);
  };

  const onFileUploadGeneralSection = async () => {
    setFilesSectionProcessing(true);

    await getUserUploadedFiles();

    setFilesSectionProcessing(false);
  };

  return (
    <div className="container">
      <div className="my-[3.5rem]">
        <h1 className="heading-1 text-site-text-heading">KYC Form</h1>

        {/* Input Forms */}
        <div className="mt-5 border border-grey-100 ">
          <header className="flex space-x-4 border-b border-grey-100 bg-[#FAFAFA] p-5">
            <div>
              <img src={MyInfoPic} alt="Info" />
            </div>
            <div>
              <h3 className="heading-3">My Information</h3>
              <p>Fill out information regarding your personal details</p>
            </div>
          </header>

          <div className="px-5 py-8">
            <div>
              <h5 className="subheading uppercase">Personal Info</h5>

              {isProcessing ? (
                <div className="flex items-center justify-center py-32">
                  <Loader />
                </div>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={generateValidationSchema} // Set the validation schema
                  onSubmit={(values) => {
                    updateProperties(values); // Pass the form values to the handleLogin function
                  }}
                >
                  {() => (
                    <Form>
                      <div className="mt-4 grid gap-5 md:grid-cols-1">
                        {fieldsData.map((field) => (
                          <div key={field.name}>
                            <p
                              dangerouslySetInnerHTML={{ __html: field.label }}
                            ></p>
                            <InputBox
                              key={field.name}
                              id={field.name}
                              type={field.type}
                              name={field.name}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="mt-12 flex w-full items-center justify-end">
                        <input
                          className="cursor-pointer  border border-grey-100 bg-primary px-3 py-2 text-site-white transition hover:bg-primary-600"
                          type="submit"
                          value="Submit Form"
                          disabled={isProcessing}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>

        {/* Documents */}
        <KYCFormUploadSection
          filesToUpload={filesToUpload}
          uploadedFiles={userUploadedFiles}
          componentLoading={filesSectionProcessing}
          onFileUploaded={() => onFileUploadGeneralSection()}
        />

        {llcSupport && (
          <KYCFormLLCUploadSection
            llcDocumentsData={llcFilesToUpload}
            uploadedFiles={userUploadedFiles}
            componentLoading={llcFilesSectionProcessing}
            onFileUploaded={() => onFileUploadLLCSection()}
          />
        )}
      </div>
    </div>
  );
};

export default KYCFormPage;
