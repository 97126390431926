export enum BUYER_SELLER_QUESTION {
    BUYER = "buyer",
    SELLER = "seller"
}

export enum NATIONALITY_QUESTION {
    MEXICAN = "mexican",
    NON_MEXICAN = "foreign"
}

export enum INDIVIDUAL_LLC_QUESTION {
    INDIVIDUAL = "individual",
    CORPORATION = "corporation",
    LLC_CORPORATION = "LLC Corporation"
}

export enum RESIDENCY_QUESTION {
    PERMANENT_RESIDENCY = "Permanent Residency",
    TEMPORARY_REIDENCY = "Temporary Residency",
    TOURIST = "Tourist"
}

