import { Form, Formik } from "formik";
import { object, string } from "yup";
import InputBox from "../../components/InputBox";
import { errorToast, successToast } from "../../helpers/toast.helper";
import { useDependency } from "../../contexts/DependencyProvider.context";

const ForgotPasswordPage: React.FC = () => {
  const { authService } = useDependency();

  const forgotPasswordSchema = object().shape({
    email: string().email("Invalid email").required("Email is required"),
  });

  const handleForgotPassword = async (data: { email: string }) => {
    try {
      await authService.requestPasswordChange(data.email);
      successToast("Reset password link sent to your email.");
    } catch (e: any) {
      errorToast(e.message);
    }
  };

  return (
    <div>
      <div className="mx-4 mt-12 rounded-md  border p-4 px-[40px] py-9 md:mx-auto md:w-[404px]">
        <h1 className="text-left text-4xl font-bold">Forgot Password</h1>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={(values, {resetForm}) => {
            handleForgotPassword(values);
            resetForm();
          }}
        >
          {() => (
            <Form>
              <div className="mb-4 mt-3">
                <InputBox
                  type="text"
                  label="Email"
                  name="email"
                  id={"email"}
                  labelClass="text-sm"
                />
              </div>

              <div className="flex justify-end">
                <input
                  type="submit"
                  className="w-full cursor-pointer bg-primary px-4 py-2 text-white hover:bg-primary-600"
                  value={"Forgot Password"}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div></div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
