import { ImSpinner8 } from "react-icons/im";
import { ChangeEvent, useState } from "react";
import { errorToast, successToast } from "../helpers/toast.helper";
import { FILE_EXTENSIONS } from "../enums/Filextension.enum";
import { RxCheck, RxEyeOpen, RxUpload } from "react-icons/rx";
import { useDependency } from "../contexts/DependencyProvider.context";
import { IGetUploadLinkParam } from "../types/services/fileService.types";
import { SUCCESS_MESSAGE } from "../enums/Messages.enum";

interface IFileUploadBoxProps {
  fileUploaded?: boolean;
  filename?: string;
  label: string;
  id: number;
  onFileUpload: () => void;
}

const FileUploadBox: React.FC<IFileUploadBoxProps> = ({
  fileUploaded = false,
  filename,
  label,
  id,
  onFileUpload,
}) => {
  const { fileService } = useDependency();

  const [isProcessing, setIsProcessing] = useState(false);

  const uploadedFileExtension = () => {
    if (filename) {
      return filename.split(".").pop() as FILE_EXTENSIONS;
    }
    return FILE_EXTENSIONS.PDF;
  };

  const uploadedFileColor = (fileExtension: FILE_EXTENSIONS) => {
    if (fileExtension === FILE_EXTENSIONS.PDF) {
      return "text-[#FD1E11]";
    } else if (fileExtension === FILE_EXTENSIONS.DOC || fileExtension === FILE_EXTENSIONS.DOCX) {
      return "text-[#295498]";
    } else if (fileExtension === FILE_EXTENSIONS.PNG || fileExtension === FILE_EXTENSIONS.JPEG) {
      return "text-[#BF413A]";
    } else {
      return "text-[#FD1E11]";
    }
  };
  

  const openFileInNewTab = async () => {
    setIsProcessing(true);

    if (!filename) return;

    try {
      const url = await fileService.getFileLink({
        filename,
      });

      window.open(url, "_blank");
    } catch (e: any) {
      errorToast(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsProcessing(true);

    const file = e.target.files![0];
    const fileExt = file.name.split(".").pop() ?? "";
    const contentType = file.type ?? "";

    const uploadFileToLinkData: IGetUploadLinkParam = {
      fileType: id,
      fileExt,
      contentType,
    };

    try {
      await fileService.uploadFileToLink(uploadFileToLinkData, file);

      successToast(SUCCESS_MESSAGE.FILE_UPLOADED);

      setTimeout(() => {
        onFileUpload();
      }, 2000); 
    } catch (e: any) {
      errorToast(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex border-collapse items-center justify-between border-b border-grey-100 px-5 py-5">
      <div className="flex items-center">
        <div className="mr-6 text-xs">
          <p
            className={`rounded-md bg-[#F1F1F1] px-1 py-2 text-xs font-semibold uppercase ${uploadedFileColor(
              uploadedFileExtension()
            )}`}
          >
            {" "}
            {uploadedFileExtension()}
          </p>
        </div>
        <p>{label}</p>
      </div>

      <div className={`flex flex-col items-center gap-5 md:flex-row ${fileUploaded ? "w-1/2 justify-end" : ""}`}>
        {fileUploaded ? (
          <>
            <button
              onClick={openFileInNewTab}
              className="active:texttext-[#8E816F] flex items-center border border-grey-100 px-3 py-2 text-[#8E816F] transition hover:border-[#8E816F] active:bg-[#f6f6f6]"
            >
              View
              <div className="ml-2 hidden md:block">
                <RxEyeOpen />
              </div>
            </button>

            <input
              type="file"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
              style={{ display: "none" }}
              onChange={handleFileChange}
              id={label + id}
            />
            <label
              htmlFor={label + id}
              className="active:texttext-[#8E816F] flex cursor-pointer items-center border border-grey-100 px-3 py-2 text-[#8E816F] transition hover:border-[#8E816F] active:bg-[#f6f6f6]"
            >
              Re-upload
              <div className="ml-2 hidden md:block">
                {isProcessing ? (
                  <ImSpinner8 className="icon-spin text-white" />
                ) : (
                  <RxUpload />
                )}
              </div>
            </label>

            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-site-green">
              {isProcessing ? (
                <ImSpinner8
                  className="icon-spin bg-white text-primary"
                  size={56}
                />
              ) : (
                <RxCheck className="text-white" size={56} />
              )}
            </div>
          </>
        ) : (
          <>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
              style={{ display: "none" }}
              onChange={handleFileChange}
              id={label + id}
            />
            <label
              htmlFor={label + id}
              className="flex cursor-pointer items-center border border-grey-100 bg-primary px-3 py-2 text-site-white transition hover:bg-primary-600"
            >
              Upload
              <div className="ml-2 hidden md:block">
                {isProcessing ? (
                  <ImSpinner8 className="icon-spin text-white" />
                ) : (
                  <RxEyeOpen />
                )}
              </div>
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUploadBox;
