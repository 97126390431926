import Loader from "../components/Loader";
import { useEffect, useRef, useState } from "react";
import { statusesData } from "../data/statusTrackingData";
import { INDIVIDUAL_LLC_QUESTION } from "../enums/Onbnoarding.enum";
import { useDependency } from "../contexts/DependencyProvider.context";

interface TrackingPageProps {
  onPercentageChange: (number: number) => void;
}

const TrackingPage: React.FC<TrackingPageProps> = ({ onPercentageChange }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [description, setDescription] = useState("");

  const { userService } = useDependency();

  // Get user prefs
  const getUserPrefs = () => userService.getUserPreferenceFromStorage();

  // Get right status object
  const getStatusObject = () => {
    const prefs = getUserPrefs();

    if (!prefs) return;

    const buyerSellerKey = prefs.buyerSellerQuestion;
    const nationality = prefs.nationalityQuestion;
    const llcIndividual =
      prefs.individualLlcQuestion === INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION
        ? "llc"
        : prefs.individualLlcQuestion;

    return statusesData[buyerSellerKey][llcIndividual][nationality];
  };

  const appropriateStatusArray = getStatusObject()!;

  // const [currentProgressStatus, setCurrentProgresStatus] = useState(
  //   appropriateStatusArray[1]
  // );

  const [currentProgressIndex, setCurrentProgressIndex] = useState(1);

  useEffect(() => {
    getCurrentUserApplicationProcess();
  }, []);

  // Send msg
  useEffect(() => {
    onPercentageChange(calculatePercentage());
  }, [currentProgressIndex]);

  const calculatePercentage = () => {
    return Math.ceil(
      (currentProgressIndex / appropriateStatusArray.length) * 100
    );
  };

  const getStatusById = (id: number) => {
    return appropriateStatusArray.find((status) => status.id === id);
  };

  const getCurrentUserApplicationProcess = async () => {
    setIsProcessing(true);

    const { status } = await userService.verifyUsers({ properties: [] });

    setIsProcessing(false);

    const currentActiveStatus = getStatusById(status)!;

    const currentIndex = appropriateStatusArray.findIndex(
      (statusObj) => statusObj.id === status
    );

    setDescription(currentActiveStatus.description);
    setCurrentProgressIndex(currentIndex + 1);
  };

  const setAndscrollToDescription = () => {
    if (descriptionRef.current) {
      descriptionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const lineProperties = {
    strokeWidth: 4,
    activeColor: "#771C15",
    inactiveColor: "#D2d2d2",
    leftOffset: 12,
    height: "5.6rem",
  };

  return (
    <div className="container">
      <div className="mt-[3.5rem]">
        <header>
          <h1 className="heading-1 text-site-text-heading">Tracking</h1>

          <div className="mt-6 md:w-2/3">
            <p>
              We appreciate your participation! To track the progress of your
              submission, please visit this page any time.
            </p>
          </div>
        </header>

        {isProcessing ? (
          <div className="flex items-center justify-center py-32">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div className="mt-8 md:flex md:space-x-8">
            <div className="flex flex-col space-y-16 md:w-2/5">
              {appropriateStatusArray.map((status, index) => (
                <div
                  onClick={() => setAndscrollToDescription()}
                  className="relative flex cursor-pointer"
                  key={status.id}
                >
                  <div className="mr-5">
                    <div className="flex h-6 w-6 flex-col items-center justify-center border-2 border-grey-100 bg-primary">
                      <input
                        className="z-20 h-5 w-5 border-2 border-grey-100 accent-primary"
                        type="checkbox"
                        name=""
                        id=""
                        readOnly
                        checked={currentProgressIndex >= index + 1}
                      />
                    </div>
                  </div>

                  {/* Dont add for final one */}
                  {appropriateStatusArray.length == index + 1 ? (
                    <></>
                  ) : (
                    <div className="absolute top-6 z-10">
                      <svg>
                        <line
                          strokeWidth={lineProperties.strokeWidth}
                          x1={lineProperties.leftOffset}
                          y1="0"
                          x2={lineProperties.leftOffset}
                          y2={lineProperties.height}
                          stroke={
                            currentProgressIndex >= index + 1
                              ? lineProperties.activeColor
                              : lineProperties.inactiveColor
                          }
                        />
                      </svg>
                    </div>
                  )}

                  <p>{status.name}</p>
                </div>
              ))}
            </div>

            <div
              ref={descriptionRef}
              className="mt-8 h-fit bg-[#F1F1F1] px-5 py-3 md:mt-0 md:flex md:w-3/5"
            >
              <p>{description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingPage;
