import {
  BUYER_SELLER_QUESTION,
  INDIVIDUAL_LLC_QUESTION,
  NATIONALITY_QUESTION,
  RESIDENCY_QUESTION,
} from "../enums/Onbnoarding.enum";
import "./Onboarding.page.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDependency } from "../contexts/DependencyProvider.context";
import { NAVIGATION } from "../enums/Navigation.enum";

interface OnboardingPageProps {
  onProgressChange: (percent: number) => void;
}

const OnboardingPage: React.FC<OnboardingPageProps> = ({
  onProgressChange,
}) => {
  const { userService } = useDependency();
  const navigate = useNavigate();

  const residencyDiv = useRef(null);

  const [showIndividualOrLlcQuestion, setIndividualOrLlcQuestion] =
    useState<boolean>(false);
  const [showNationalityQuestion, setNationalityQuetion] =
    useState<boolean>(false);
  const [showResidencyQuestion, setShowResidencyQuestion] =
    useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);

  const [buyerSellerAnswer, setBuyerSellerAnswer] = useState<
    BUYER_SELLER_QUESTION | undefined
  >(undefined);
  const [individualLlcAnswer, setIndividualLlcAnswer] = useState<
    INDIVIDUAL_LLC_QUESTION | undefined
  >(undefined);
  const [nationalityAnswer, setNationalityAnswer] = useState<
    NATIONALITY_QUESTION | undefined
  >(undefined);
  const [residencyAnswer, setResidencyAnswer] = useState<
    RESIDENCY_QUESTION | undefined
  >(undefined);

  // Update percent
  useEffect(() => {
    onProgressChange(calculatePercent());
  }, [buyerSellerAnswer, individualLlcAnswer, nationalityAnswer]);

  // Preset values
  useEffect(() => {
    setInitialUserPrefs();
  }, []);

  const hideResidencyDiv = (showResidency: boolean) => {
    if (residencyDiv.current) {
      if (showResidency) {
        (residencyDiv.current as HTMLElement).style.display = "block";
      } else {
        (residencyDiv.current as HTMLElement).style.display = "none";
      }
    }
  }

  const storeUserPrefs = async () => {
    if (buyerSellerAnswer && individualLlcAnswer && nationalityAnswer) {
      await userService.storeUserPreference({
        buyerSellerQuestion: buyerSellerAnswer,
        individualLlcQuestion: individualLlcAnswer,
        nationalityQuestion: nationalityAnswer,
        residencyQuestion: residencyAnswer,
      });
    }
  };

  const onKycNext = async () => {
    await storeUserPrefs();

    navigate(NAVIGATION.KYC);
  };

  const calculatePercent = () => {
    const answeredQuestions = [
      buyerSellerAnswer,
      individualLlcAnswer,
      nationalityAnswer,
    ];

    const answeredCount = answeredQuestions.filter(Boolean).length;
    const percent = answeredCount * 33.33;

    return Math.ceil(percent);
  };

  const setInitialUserPrefs = () => {
    const data = userService.getUserPreferenceFromStorage();

    if (data) {
      const {
        buyerSellerQuestion,
        individualLlcQuestion,
        nationalityQuestion,
        residencyQuestion,
      } = data;

      setBuyerSellerAnswer(buyerSellerQuestion);
      setIndividualLlcAnswer(individualLlcQuestion);
      setNationalityAnswer(nationalityQuestion);
      setResidencyAnswer(residencyQuestion);

      // Show the items
      setIndividualOrLlcQuestion(true);
      setNationalityQuetion(true);
      hideResidencyDiv(nationalityAnswer === NATIONALITY_QUESTION.MEXICAN)

      if (residencyQuestion) setShowResidencyQuestion(true);

      setShowControls(true);
    }
  };

  const onBuyerSellerQuestionClick = (answer: BUYER_SELLER_QUESTION) => {
    setIndividualOrLlcQuestion(true);
    setBuyerSellerAnswer(answer);
  };

  const onIndividualLlcQuestionClick = (answer: INDIVIDUAL_LLC_QUESTION) => {
    setNationalityQuetion(true);
    setIndividualLlcAnswer(answer);
  };

  const onNationalityQuestionClick = (answer: NATIONALITY_QUESTION) => {
    setNationalityAnswer(answer);

    const showResidency = answer === NATIONALITY_QUESTION.NON_MEXICAN;

    setShowResidencyQuestion(showResidency);
    setShowControls(!showResidency);

    hideResidencyDiv(showResidency);
  };

  const onResidencyQuestionClick = (answer: RESIDENCY_QUESTION) => {
    setShowControls(true);
    setResidencyAnswer(answer);
  };

  return (
    <div className="container">
      <div className="mt-[3.5rem]">
        <h1 className="heading-1 text-site-text-heading">
          Are you a Buyer or Seller?
        </h1>

        <p className="mt-6">
          Please select whether you are participating in the transaction as a
          buyer or a seller. This will help us tailor the process and provide
          the most relevant information for your role.
        </p>

        <div className="mt-12">
          <div className="grid grid-cols-2 gap-5">
            <button
              onClick={() =>
                onBuyerSellerQuestionClick(BUYER_SELLER_QUESTION.BUYER)
              }
              className={`big-button big-button-text capitalize ${
                buyerSellerAnswer === BUYER_SELLER_QUESTION.BUYER
                  ? "--active"
                  : ""
              }`}
            >
              {BUYER_SELLER_QUESTION.BUYER}
            </button>
            <button
              onClick={() =>
                onBuyerSellerQuestionClick(BUYER_SELLER_QUESTION.SELLER)
              }
              className={`big-button big-button-text capitalize ${
                buyerSellerAnswer === BUYER_SELLER_QUESTION.SELLER
                  ? "--active"
                  : ""
              }`}
            >
              {BUYER_SELLER_QUESTION.SELLER}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`mt-24 transition ease-in-out ${
          showIndividualOrLlcQuestion ? "opacity-100" : " opacity-0"
        }`}
      >
        <h1 className="heading-1 text-site-text-heading">
          Are you an Individual, Corporation or LLC Corporation?
        </h1>

        <p className="mt-6">
          Let us know your nationality. We have specific steps for Mexican and
          non-Mexican clients to ensure a smooth real estate transaction. This
          information helps us to provide you with the most accurate and
          relevant support.
        </p>

        <div className="mt-12">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
            <button
              onClick={() =>
                onIndividualLlcQuestionClick(INDIVIDUAL_LLC_QUESTION.INDIVIDUAL)
              }
              className={`big-button big-button-text capitalize ${
                individualLlcAnswer === INDIVIDUAL_LLC_QUESTION.INDIVIDUAL
                  ? "--active"
                  : ""
              }`}
            >
              {INDIVIDUAL_LLC_QUESTION.INDIVIDUAL}
            </button>

            <button
              onClick={() =>
                onIndividualLlcQuestionClick(INDIVIDUAL_LLC_QUESTION.CORPORATION)
              }
              className={`big-button big-button-text capitalize ${
                individualLlcAnswer === INDIVIDUAL_LLC_QUESTION.CORPORATION
                  ? "--active"
                  : ""
              }`}
            >
              {INDIVIDUAL_LLC_QUESTION.CORPORATION}
            </button>

            <button
              onClick={() =>
                onIndividualLlcQuestionClick(INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION)
              }
              className={`big-button big-button-text capitalize ${
                individualLlcAnswer === INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION
                  ? "--active"
                  : ""
              }`}
            >
              {INDIVIDUAL_LLC_QUESTION.LLC_CORPORATION}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`mt-24 transition ease-in-out ${
          showNationalityQuestion ? " opacity-100" : " opacity-0"
        }`}
      >
        <h1 className="heading-1 text-site-text-heading">
          What is your Nationality?
        </h1>

        <p className="mt-6">
          Let us know your nationality. We have specific steps for Mexican and
          non-Mexican clients to ensure a smooth real estate transaction. This
          information helps us to provide you with the most accurate and
          relevant support.
        </p>

        <div className="mt-12">
          <div className="grid grid-cols-2 gap-5">
            <button
              onClick={() =>
                onNationalityQuestionClick(NATIONALITY_QUESTION.MEXICAN)
              }
              className={`big-button big-button-text capitalize ${
                nationalityAnswer === NATIONALITY_QUESTION.MEXICAN
                  ? "--active"
                  : ""
              }`}
            >
              {NATIONALITY_QUESTION.MEXICAN}
            </button>
            <button
              onClick={() =>
                onNationalityQuestionClick(NATIONALITY_QUESTION.NON_MEXICAN)
              }
              className={`big-button big-button-text capitalize ${
                nationalityAnswer === NATIONALITY_QUESTION.NON_MEXICAN
                  ? "--active"
                  : ""
              }`}
            >
              {NATIONALITY_QUESTION.NON_MEXICAN}
            </button>
          </div>
        </div>
      </div>

      <div
        ref={residencyDiv}
        className={`mt-24 transition ease-in-out ${
          showResidencyQuestion ? " opacity-100" : " opacity-0"
        }`}
      >
        <h1 className="heading-1 text-site-text-heading">Type of residency?</h1>

        <p className="mt-6">
          The question "Type of residency?" seeks to identify the specific legal
          status or category of an individual's residence in a particular
          country. This information is crucial for determining their rights,
          privileges, and responsibilities, as well as their eligibility for
          various benefits, services, or legal requirements associated with
          their residency status.
        </p>

        <div className="mt-12">
          <div className="grid grid-cols-3 gap-5">
            <button
              onClick={() =>
                onResidencyQuestionClick(RESIDENCY_QUESTION.PERMANENT_RESIDENCY)
              }
              className={`big-button big-button-text ${
                residencyAnswer === RESIDENCY_QUESTION.PERMANENT_RESIDENCY
                  ? "--active"
                  : ""
              }`}
            >
              {RESIDENCY_QUESTION.PERMANENT_RESIDENCY}
            </button>
            <button
              onClick={() =>
                onResidencyQuestionClick(RESIDENCY_QUESTION.TEMPORARY_REIDENCY)
              }
              className={`big-button big-button-text ${
                residencyAnswer === RESIDENCY_QUESTION.TEMPORARY_REIDENCY
                  ? "--active"
                  : ""
              }`}
            >
              {RESIDENCY_QUESTION.TEMPORARY_REIDENCY}
            </button>
            <button
              onClick={() =>
                onResidencyQuestionClick(RESIDENCY_QUESTION.TOURIST)
              }
              className={`big-button big-button-text ${
                residencyAnswer === RESIDENCY_QUESTION.TOURIST ? "--active" : ""
              }`}
            >
              {RESIDENCY_QUESTION.TOURIST}
            </button>
          </div>
        </div>
      </div>

      {/* Controls */}
      <div
        className={` my-24 mt-24 flex justify-end space-x-2.5 transition ease-in-out ${
          showControls ? " opacity-100" : " opacity-0"
        }`}
      >
        <a
          onClick={onKycNext}
          className="cursor-pointer border border-grey-100 bg-primary px-10 py-3 font-bold text-site-white transition hover:bg-primary-600"
        >
          Next
        </a>
      </div>
    </div>
  );
};

export default OnboardingPage;
