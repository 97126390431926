import { Navigate } from "react-router-dom";
import TokenHelper from "../helpers/Token.helper";

export type AuthProtectedRouteProps = {
  redirectPath: string;
  outlet: JSX.Element;
};

export default function AuthProtectedRoute({
  redirectPath,
  outlet,
}: AuthProtectedRouteProps) {
  const tokenHelper = new TokenHelper();
  if (tokenHelper.getToken() == "") {
    return outlet;
  } else {
    return <Navigate to={{ pathname: redirectPath }} />;
  }
}
