import ITokenHelper from "../types/helpers/tokenHelper.type";

class TokenHelper implements ITokenHelper {
    private readonly TOKEN_KEY = 'grouponarAuthToken';

    getToken(): string {
        const token = localStorage.getItem(this.TOKEN_KEY);
        return token ? token : '';
    }

    setToken(token: string): void {
        localStorage.setItem(this.TOKEN_KEY, token);
    }

    clearToken(): void {
        localStorage.clear();
    }
}

export default TokenHelper;
