import { inject, injectable } from "tsyringe";
import ApiClient from "../helpers/ApiClient.helper";
import * as apiClientType from "../types/helpers/apiClient.type";
import { ICreatePropertiesParams, IGetUserPropertiesParam, IGetUserPropertiesResponse, IStoreUserPreferenceParams, IStoreUserPreferenceResponse, IUserService, IVerifyUserParams, IVerifyUsersResponse } from "../types/services/userService.type";
import { BUYER_SELLER_QUESTION, INDIVIDUAL_LLC_QUESTION, NATIONALITY_QUESTION, RESIDENCY_QUESTION } from '../enums/Onbnoarding.enum';

@injectable()
export default class UserService implements IUserService {
    apiClient: apiClientType.IApiClient;
    grouponarKey = "grouponar_user_preferance"

    constructor(@inject(ApiClient) apiClient: apiClientType.IApiClient) {
        this.apiClient = apiClient;
    }

    async verifyUsers(params: IVerifyUserParams): Promise<IVerifyUsersResponse> {
        const response = await this.apiClient.post<IVerifyUserParams, IVerifyUsersResponse>("/users/auth/verify", params);

        return response;
    }

    async getUserProperties(params: IGetUserPropertiesParam): Promise<IGetUserPropertiesResponse> {
        const { deal } = await this.verifyUsers(params);

        return deal.properties;
    }

    async storeUserPreference(params: IStoreUserPreferenceParams): Promise<string> {
        localStorage.setItem(this.grouponarKey, JSON.stringify(params));

        const { buyerSellerQuestion, individualLlcQuestion, nationalityQuestion, residencyQuestion } = params;

        const dataToSend = {
            buyer_or_seller: buyerSellerQuestion,
            individual_or_llc: individualLlcQuestion,
            nationality: nationalityQuestion,
            type_of_residency: ''
        }

        if (residencyQuestion) {
            dataToSend.type_of_residency = residencyQuestion;
        }

        const response = await this.updateProperties(dataToSend);

        return response.message;
    }

    getUserPreferenceFromStorage(): IStoreUserPreferenceParams | undefined {
        const textItem = localStorage.getItem(this.grouponarKey);
        let items;

        if (textItem && textItem != "undefined") {
            items = JSON.parse(textItem) as IStoreUserPreferenceParams;

            if (!items.buyerSellerQuestion || !items.individualLlcQuestion || !items.nationalityQuestion) {
                return undefined;
            }
        }
        
        return items;
    }

    async getUserPreferenceFromAPI(): Promise<IStoreUserPreferenceParams> {
        const { buyer_or_seller, individual_or_llc, nationality, type_of_residency } = await this.getUserProperties({ properties: ["buyer_or_seller", "individual_or_llc", "nationality", "type_of_residency"] });

        return {
            buyerSellerQuestion: buyer_or_seller as BUYER_SELLER_QUESTION,
            individualLlcQuestion: individual_or_llc as INDIVIDUAL_LLC_QUESTION,
            nationalityQuestion: nationality as NATIONALITY_QUESTION,
            residencyQuestion: type_of_residency as RESIDENCY_QUESTION
        }
    }


    updateProperties(params: ICreatePropertiesParams): Promise<IStoreUserPreferenceResponse> {
        return this.apiClient.put<ICreatePropertiesParams, IStoreUserPreferenceResponse>("/users/hubspot-deal-props", params);
    }
}