const commonStatusData = [
    {
      name: 'Purchase Agreement Signed',
      id: 95364923,
      description: "The Purchase Agreement, outlining the terms and conditions of the property sale, has been successfully signed by all parties involved in the transaction. This agreement serves as a legally binding contract, setting the groundwork for the subsequent steps in the property transfer process."
    },
    {
      name: 'Escrow Account Opened (Optional)',
      id: 95364924,
      description: "An Escrow Account has been optionally opened to facilitate a secure holding of funds during the property transaction. This account acts as a neutral intermediary, ensuring that the buyer's funds are safely deposited and that the seller can proceed with confidence, knowing that the necessary finances are available to complete the sale."
    },
    {
      name: 'Documents Received and KYC Filled',
      id: 95364925,
      description: "All required documents for the property transaction have been received, and the Know Your Customer (KYC) process has been diligently filled. The submission of necessary documents and KYC information is a critical step to verify the identities of the parties involved and to ensure compliance with applicable laws and regulations."
    },
    {
      name: 'Closing Costs Sent to Buyer',
      id: 95364928,
      description: "The closing costs, which encompass various fees associated with the property transfer, have been sent to the buyer. These costs typically include expenses related to title searches, appraisals, insurance, and administrative fees. The buyer is now prepared to fulfill their financial obligations in preparation for the property closing."
    },
    {
      name: 'Payment of Closing Costs by Buyer',
      id: 95364929,
      description: "The buyer has successfully made the payment for the closing costs associated with the property purchase. This step finalizes the financial aspects of the transaction, bringing the buyer one step closer to the formal transfer of property ownership."
    },
    {
      name: 'No Lien Certificate',
      id: 95419305,
      description: "A Certificate of No Lien has been obtained, indicating that there are no outstanding claims or encumbrances on the property. This certificate serves as a crucial assurance for both the buyer and the seller, as it confirms that the property is free from any legal or financial burdens, providing a smooth pathway to complete the sale."
    },
    {
      name: 'Appraisal',
      id: 95419306,
      description: "The property has undergone a professional appraisal to assess its fair market value. Appraisals play a vital role in determining the property's worth and assist buyers, sellers, and lenders in making informed decisions. A favorable appraisal ensures that the property's value aligns with the agreed-upon purchase price."
    },
    {
      name: 'PERMISO DE RELACIONES EXTERIORES',
      id: 95350183,
      description: "The 'Permiso de Relaciones Exteriores' refers to the 'Permission from the Ministry of Foreign Affairs' in Spanish. This document is typically required for certain international activities or transactions involving individuals or entities from different countries."
    },
    {
      name: 'KYC y Formatos del Fiduciario Firmados',
      id: 95350184,
      description: "Los documentos de Conozca a su Cliente (KYC) y los Formatos del Fiduciario han sido firmados por las partes involucradas. Estos documentos son esenciales para verificar la identidad de las partes y cumplir con las leyes y regulaciones aplicables en la transacción fiduciaria."
    },
    {
      name: 'KYC y Formatos del Fiduciario Enviados',
      id: 95350185,
      description: "Los documentos de Conozca a su Cliente (KYC) y los Formatos del Fiduciario han sido enviados para su revisión. Estos documentos son esenciales para verificar la identidad de las partes y cumplir con las leyes y regulaciones aplicables en la transacción fiduciaria."
    },
    {
      name: 'Certificate of No Dues of Property Tax',
      id: 95350186,
      description: "A Certificate of No Dues for Property Tax has been obtained, certifying that there are no outstanding dues or taxes related to the property. This certificate is a crucial document, ensuring that the property's tax obligations have been fulfilled, and there are no lingering financial liabilities for the new owner."
    },
    {
      name: 'Letter of No HOA Dues',
      id: 95350187,
      description: "A Letter of No Homeowners Association (HOA) Dues has been received, confirming that there are no unpaid dues or assessments associated with the property. This letter provides assurance to both the buyer and the seller that the property is not subject to any outstanding HOA obligations that may affect the transfer of ownership."
    },
    {
      name: 'Draft of Deed Sent/Reviewed by Parties',
      id: 95350188,
      description: "The draft of the property deed has been sent and reviewed by all parties involved. The property deed is a legal document that transfers ownership from the seller to the buyer. Reviewing the draft ensures that all parties are in agreement with its contents before proceeding with the final signing and notarization."
    },
    {
      name: 'Signed Deed',
      id: 95350189,
      description: "The property deed has been duly signed by all relevant parties involved in the transaction. This critical step finalizes the transfer of ownership, officially granting the buyer full rights to the property while relinquishing the seller's legal claim."
    },
    {
      name: 'Deed Ready for Pick Up at Notary',
      id: 95350190,
      description: "The property deed is now ready for pickup at the notary's office. Having completed all necessary steps, the finalized deed awaits retrieval by the parties involved, officially completing the property transfer process."
    },
  ];
  
  const buyerLLCPayStatusData = [
    {
      name: 'Purchase Agreement Signed',
      id: 95364923,
      description: "The Purchase Agreement, outlining the terms and conditions of the property sale, has been successfully signed by all parties involved in the transaction. This agreement serves as a legally binding contract, setting the groundwork for the subsequent steps in the property transfer process."
    },
    {
      name: 'Escrow Account Opened (Optional)',
      id: 95364924,
      description: "An Escrow Account has been optionally opened to facilitate a secure holding of funds during the property transaction. This account acts as a neutral intermediary, ensuring that the buyer's funds are safely deposited and that the seller can proceed with confidence, knowing that the necessary finances are available to complete the sale."
    },
    {
      name: 'Documents Received and KYC Filled',
      id: 95364925,
      description: "All required documents for the property transaction have been received, and the Know Your Customer (KYC) process has been diligently filled. The submission of necessary documents and KYC information is a critical step to verify the identities of the parties involved and to ensure compliance with applicable laws and regulations."
    },
    {
      name: 'Documents Received Notarized and Apostilled',
      id: 95364926,
      description: "The necessary documents for the property transaction have been received and have undergone the notarization and apostille process. Notarization involves the authentication of documents by a notary public to confirm their legitimacy and authenticity. Apostille, on the other hand, is an additional step of authentication, usually required for international transactions, where a government authority certifies the validity of the notary's signature. The notarization and apostille processes add an extra layer of credibility to the documents, making them legally recognized and valid both nationally and internationally."
    },
    {
      name: "Translation of LLC Documents Completed",
      id: 95364927,
      description: "Description: The process of translating LLC (Limited Liability Company) documents has been successfully concluded. Translating these documents is crucial, especially in international business transactions or dealings involving parties with different native languages. Accurate translation ensures that all parties involved can fully comprehend the content and implications of the LLC documents. By completing this translation step, potential misunderstandings and discrepancies are minimized, promoting clear communication and legal compliance throughout the business or property transaction."
    },
    {
      name: 'Closing Costs Sent to Buyer',
      id: 95364928,
      description: "The closing costs, which encompass various fees associated with the property transfer, have been sent to the buyer. These costs typically include expenses related to title searches, appraisals, insurance, and administrative fees. The buyer is now prepared to fulfill their financial obligations in preparation for the property closing."
    },
    {
      name: 'Payment of Closing Costs by Buyer',
      id: 95364929,
      description: "The buyer has successfully made the payment for the closing costs associated with the property purchase. This step finalizes the financial aspects of the transaction, bringing the buyer one step closer to the formal transfer of property ownership."
    },
    {
      name: 'No Lien Certificate',
      id: 95419305,
      description: "A Certificate of No Lien has been obtained, indicating that there are no outstanding claims or encumbrances on the property. This certificate serves as a crucial assurance for both the buyer and the seller, as it confirms that the property is free from any legal or financial burdens, providing a smooth pathway to complete the sale."
    },
    {
      name: 'Appraisal',
      id: 95419306,
      description: "The property has undergone a professional appraisal to assess its fair market value. Appraisals play a vital role in determining the property's worth and assist buyers, sellers, and lenders in making informed decisions. A favorable appraisal ensures that the property's value aligns with the agreed-upon purchase price."
    },
    {
      name: 'PERMISO DE RELACIONES EXTERIORES',
      id: 95350183,
      description: "The 'Permiso de Relaciones Exteriores' refers to the 'Permission from the Ministry of Foreign Affairs' in Spanish. This document is typically required for certain international activities or transactions involving individuals or entities from different countries."
    },
    {
      name: 'KYC y Formatos del Fiduciario Firmados',
      id: 95350184,
      description: "Los documentos de Conozca a su Cliente (KYC) y los Formatos del Fiduciario han sido firmados por las partes involucradas. Estos documentos son esenciales para verificar la identidad de las partes y cumplir con las leyes y regulaciones aplicables en la transacción fiduciaria."
    },
    {
      name: 'KYC y Formatos del Fiduciario Enviados',
      id: 95350185,
      description: "Los documentos de Conozca a su Cliente (KYC) y los Formatos del Fiduciario han sido enviados para su revisión. Estos documentos son esenciales para verificar la identidad de las partes y cumplir con las leyes y regulaciones aplicables en la transacción fiduciaria."
    },
    {
      name: 'Certificate of No Dues of Property Tax',
      id: 95350186,
      description: "A Certificate of No Dues for Property Tax has been obtained, certifying that there are no outstanding dues or taxes related to the property. This certificate is a crucial document, ensuring that the property's tax obligations have been fulfilled, and there are no lingering financial liabilities for the new owner."
    },
    {
      name: 'Letter of No HOA Dues',
      id: 95350187,
      description: "A Letter of No Homeowners Association (HOA) Dues has been received, confirming that there are no unpaid dues or assessments associated with the property. This letter provides assurance to both the buyer and the seller that the property is not subject to any outstanding HOA obligations that may affect the transfer of ownership."
    },
    {
      name: 'Draft of Deed Sent/Reviewed by Parties',
      id: 95350188,
      description: "The draft of the property deed has been sent and reviewed by all parties involved. The property deed is a legal document that transfers ownership from the seller to the buyer. Reviewing the draft ensures that all parties are in agreement with its contents before proceeding with the final signing and notarization."
    },
    {
      name: 'Signed Deed',
      id: 95350189,
      description: "The property deed has been duly signed by all relevant parties involved in the transaction. This critical step finalizes the transfer of ownership, officially granting the buyer full rights to the property while relinquishing the seller's legal claim."
    },
    {
      name: 'Deed Ready for Pick Up at Notary',
      id: 95350190,
      description: "The property deed is now ready for pickup at the notary's office. Having completed all necessary steps, the finalized deed awaits retrieval by the parties involved, officially completing the property transfer process."
    },
  ];
  
  const mexicanStatusData = [
    {
      name: 'Purchase Agreement Signed',
      id: 95364923,
      description: "The Purchase Agreement, outlining the terms and conditions of the property sale, has been successfully signed by all parties involved in the transaction. This agreement serves as a legally binding contract, setting the groundwork for the subsequent steps in the property transfer process."
    },
    {
      name: 'Escrow Account Opened (Optional)',
      id: 95364924,
      description: "An Escrow Account has been optionally opened to facilitate a secure holding of funds during the property transaction. This account acts as a neutral intermediary, ensuring that the buyer's funds are safely deposited and that the seller can proceed with confidence, knowing that the necessary finances are available to complete the sale."
    },
    {
      name: 'Documents Received and KYC Filled',
      id: 95364925,
      description: "All required documents for the property transaction have been received, and the Know Your Customer (KYC) process has been diligently filled. The submission of necessary documents and KYC information is a critical step to verify the identities of the parties involved and to ensure compliance with applicable laws and regulations."
    },
    {
      name: 'Closing Costs Sent to Buyer',
      id: 95364928,
      description: "The closing costs, which encompass various fees associated with the property transfer, have been sent to the buyer. These costs typically include expenses related to title searches, appraisals, insurance, and administrative fees. The buyer is now prepared to fulfill their financial obligations in preparation for the property closing."
    },
    {
      name: 'Payment of Closing Costs by Buyer',
      id: 95364929,
      description: "The buyer has successfully made the payment for the closing costs associated with the property purchase. This step finalizes the financial aspects of the transaction, bringing the buyer one step closer to the formal transfer of property ownership."
    },
    {
      name: 'No Lien Certificate',
      id: 95419305,
      description: "A Certificate of No Lien has been obtained, indicating that there are no outstanding claims or encumbrances on the property. This certificate serves as a crucial assurance for both the buyer and the seller, as it confirms that the property is free from any legal or financial burdens, providing a smooth pathway to complete the sale."
    },
    {
      name: 'Appraisal',
      id: 95419306,
      description: "The property has undergone a professional appraisal to assess its fair market value. Appraisals play a vital role in determining the property's worth and assist buyers, sellers, and lenders in making informed decisions. A favorable appraisal ensures that the property's value aligns with the agreed-upon purchase price."
    },
    {
      name: 'PERMISO DE RELACIONES EXTERIORES',
      id: 95350183,
      description: "The 'Permiso de Relaciones Exteriores' refers to the 'Permission from the Ministry of Foreign Affairs' in Spanish. This document is typically required for certain international activities or transactions involving individuals or entities from different countries."
    },
    {
      name: 'Certificate of No Dues of Property Tax',
      id: 95350186,
      description: "A Certificate of No Dues for Property Tax has been obtained, certifying that there are no outstanding dues or taxes related to the property. This certificate is a crucial document, ensuring that the property's tax obligations have been fulfilled, and there are no lingering financial liabilities for the new owner."
    },
    {
      name: 'Letter of No HOA Dues',
      id: 95350187,
      description: "A Letter of No Homeowners Association (HOA) Dues has been received, confirming that there are no unpaid dues or assessments associated with the property. This letter provides assurance to both the buyer and the seller that the property is not subject to any outstanding HOA obligations that may affect the transfer of ownership."
    },
    {
      name: 'Draft of Deed Sent/Reviewed by Parties',
      id: 95350188,
      description: "The draft of the property deed has been sent and reviewed by all parties involved. The property deed is a legal document that transfers ownership from the seller to the buyer. Reviewing the draft ensures that all parties are in agreement with its contents before proceeding with the final signing and notarization."
    },
    {
      name: 'Signed Deed',
      id: 95350189,
      description: "The property deed has been duly signed by all relevant parties involved in the transaction. This critical step finalizes the transfer of ownership, officially granting the buyer full rights to the property while relinquishing the seller's legal claim."
    },
    {
      name: 'Deed Ready for Pick Up at Notary',
      id: 95350190,
      description: "The property deed is now ready for pickup at the notary's office. Having completed all necessary steps, the finalized deed awaits retrieval by the parties involved, officially completing the property transfer process."
    },
  ]
  
  export const statusesData = {
    seller: {
      individual: {
        foreign: commonStatusData,
        mexican: mexicanStatusData,
      },
      llc: {
        foreign: commonStatusData,
        mexican: commonStatusData
      },
      corporation: {
        foreign: commonStatusData,
        mexican: commonStatusData
      }
    },
    buyer: {
      individual: {
        foreign: commonStatusData,
        mexican: mexicanStatusData
      },
      llc: {
        foreign: buyerLLCPayStatusData,
        mexican: buyerLLCPayStatusData
      },
      corporation: {
        foreign: commonStatusData,
        mexican: commonStatusData
      }
    },
  };