import React, { createContext, ReactNode, useContext } from "react";

import ITokenHelper from "../types/helpers/tokenHelper.type";
import { IAuthService } from "../types/services/authService.type";
import { IUserService } from "../types/services/userService.type";
import { IFileService } from "../types/services/fileService.types";


export interface IServicesDependency {
  authService: IAuthService;
  fileService: IFileService;
  userService: IUserService;
  tokenHelper: ITokenHelper
}

const DependencyContext = createContext<Partial<IServicesDependency>>({});

export const useDependency = (): IServicesDependency => {
  const dependency = useContext(DependencyContext);

  if (!dependency) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }

  return dependency as IServicesDependency;
};

interface IDependencyProviderParams {
  services: IServicesDependency;
  children: ReactNode;
}

//Create a settings provider component
export const DependencyProvider: React.FC<IDependencyProviderParams> = ({
  services,
  children,
}): ReactNode => (
  <DependencyContext.Provider value={services}>
    {children}
  </DependencyContext.Provider>
);
