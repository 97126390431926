import { useState } from "react";
import "./ProcessLayout.scss";
import { NavLink, Outlet } from "react-router-dom";
import { NAVIGATION } from "../enums/Navigation.enum";

interface IProcessLayoutProps {
  onboardingProgressPercent: number;
  kycProgressPercent: number;
  trackingProgressPercent: number;
  enableOtherNavigations: boolean;
}

const OnboardingLink: React.FC<{
  onboardingProgressPercent: number;
  showToolTip: boolean;
}> = ({ onboardingProgressPercent, showToolTip }) => (
  <div className="relative">
    <NavLink
      className={({ isActive }) =>
        isActive ? "tab-link --active" : "tab-link --inactive"
      }
      to={NAVIGATION.ONBOARDING}
    >
      <span className="hidden text-[#22130B] md:inline">
        {onboardingProgressPercent === 0 ? "" : onboardingProgressPercent + "%"}
      </span>
      <span className="link-name">Onboarding</span>
    </NavLink>

    {showToolTip && (
      <div className="absolute top-2 w-72 md:-right-1/2">
        <div
          className="absolute left-10 top-10 -mt-2 -translate-x-1/2 transform transition-opacity duration-300 md:left-1/2"
          style={{ opacity: showToolTip ? 1 : 0 }}
        >
          <div className="h-4 w-4 origin-bottom rotate-45 transform bg-primary-600"></div>
        </div>

        <div
          className="absolute top-10 bg-primary-600 px-2 py-1 text-center transition-opacity duration-300"
          style={{ opacity: showToolTip ? 1 : 0 }}
        >
          <p className="text-xs text-white md:text-base">
            Please complete onboarding page
          </p>
        </div>
      </div>
    )}
  </div>
);

const KycLink: React.FC<{
  kycProgressPercent: number;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}> = ({ kycProgressPercent, onClick }) => (
  <div>
    <NavLink
      className={({ isActive }) =>
        isActive ? "tab-link --active" : "tab-link --inactive"
      }
      onClick={onClick}
      to={NAVIGATION.KYC}
    >
      <span className="hidden text-[#22130B] md:inline">
        {kycProgressPercent === 0 ? "" : kycProgressPercent + "%"}
      </span>{" "}
      <span className="link-name">
        KYC <span className="hidden md:inline">and File Upload</span>
      </span>
    </NavLink>
  </div>
);

const ProgressLink: React.FC<{
  trackingProgressPercent: number;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}> = ({ trackingProgressPercent, onClick }) => (
  <div className="py-3">
    <NavLink
      className={({ isActive }) =>
        isActive ? "tab-link --active" : "tab-link --inactive"
      }
      onClick={onClick}
      to={NAVIGATION.TRACKING}
    >
      <span className="hidden text-[#22130B] md:inline">
        {trackingProgressPercent === 0 ? "" : trackingProgressPercent + "%"}
      </span>
      <span className="link-name">Progress</span>
    </NavLink>
  </div>
);

const ProcessLayout: React.FC<IProcessLayoutProps> = ({
  onboardingProgressPercent,
  kycProgressPercent,
  trackingProgressPercent,
  enableOtherNavigations,
}) => {
  const timeToShowToolTip = 3000;

  const [showToolTip, setShowToolTip] = useState(false);

  const onKycFormLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (enableOtherNavigations) return;

    e.preventDefault();

    showHideToolTip();
  };

  const onProgressLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (enableOtherNavigations) return;

    e.preventDefault();

    showHideToolTip();
  };

  const showHideToolTip = () => {
    setShowToolTip(true);

    return setTimeout(() => {
      setShowToolTip(false);
    }, timeToShowToolTip);
  };

  return (
    <div>
      <nav className="flex justify-center">
        <div className="flex items-center justify-center">
          <OnboardingLink
            onboardingProgressPercent={onboardingProgressPercent}
            showToolTip={showToolTip}
          />

          <KycLink
            kycProgressPercent={kycProgressPercent}
            onClick={(e) => onKycFormLinkClick(e)}
          />

          <ProgressLink
            trackingProgressPercent={trackingProgressPercent}
            onClick={(e) => onProgressLinkClick(e)}
          />
        </div>
      </nav>

      <div className="process-content">
        <Outlet />
      </div>
    </div>
  );
};

export default ProcessLayout;
