import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Logo from "../assets/logo-white.png";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "../enums/Navigation.enum";
import { useDependency } from "../contexts/DependencyProvider.context";
import { errorToast } from "../helpers/toast.helper";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { authService, tokenHelper } = useDependency();

  const [showComingBackMsg, setShowComingBackMsg] = useState<boolean>(true);

  const logout = () => {
    try {
      authService.logOut();
      navigate(NAVIGATION.LOGIN);
    } catch (e: any) {
      errorToast("Couldn't log in");
    }
  };

  return (
    <>
      <div>
        <nav className="bg-site-black py-4">
          <div className="container flex items-center justify-between">
            <img className="w-20" src={Logo} alt="Grupo NAR" />

            {tokenHelper.getToken() && (
              <div>
                <a
                  className="cursor-pointer  border border-grey-100 bg-primary px-3 py-2 text-site-white transition hover:bg-primary-600"
                  onClick={logout}
                >
                  Logout
                </a>
              </div>
            )}
          </div>
        </nav>
      </div>
      <div
        className={`bg-primary transition ease-in-out ${
          showComingBackMsg ? "h-auto" : "h-0"
        }`}
      >
        <div className="container">
          <div className="flex items-center justify-center gap-2 py-2">
            <p className="text-site-white">
              Remember. You can come back later to edit details.
            </p>
            <button onClick={() => setShowComingBackMsg(false)}>
              <RxCross1 className="text-site-white" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
