import { object, string } from "yup";
import { Formik, Form } from "formik";
import InputBox from "../../components/InputBox";
import { errorToast, successToast } from "../../helpers/toast.helper";
import { NavLink, useNavigate } from "react-router-dom";
import { useDependency } from "../../contexts/DependencyProvider.context";
import { RegisterMethodParam } from "../../types/services/authService.type";
import { NAVIGATION } from "../../enums/Navigation.enum";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { authService } = useDependency();

  const registerSchema = object().shape({
    email: string().email("Invalid email").required("Email is required"),
    name: string().required("Name is required"),
    company: string().required("Company Name is required"),
    password: string().required("Password is required"),
    passwordConfirm: string().required("Confirm Password is required")
  });

  const handleSubmit = async (values: RegisterMethodParam) => {
    console.log("On submit");
    try {
      await authService.register(values);

      successToast("Sucessfully registered. Please verify your email and login.")

      navigate(NAVIGATION.LOGIN);
    } catch (e: any) {
      errorToast(e.message);
    }
  };

  return (
    <div className="mx-4 mt-12 rounded-md  border p-4 px-[40px] py-9 md:mx-auto md:w-[404px]">
      {" "}
      <h1 className="text-left text-3xl font-bold">Register</h1>
      <Formik
        initialValues={{ email: "", name: "", company: "", password: "", passwordConfirm: "" }}
        validationSchema={registerSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {() => (
          <Form>
            <div className="mb-4">
              <InputBox
                label="Name"
                type="text"
                name="name"
                id="name"
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4 mt-3">
              <InputBox
                label="Email"
                type="email"
                name="email"
                id="email"
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4">
              <InputBox
                label="Company Name"
                type="text"
                name="company"
                id="companyName"
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4">
              <InputBox
                label="Password"
                type="password"
                name="password"
                id="password"
                labelClass="text-sm"
              />
            </div>

            <div className="mb-4">
              <InputBox
                label="Confirm Password"
                type="password"
                name="passwordConfirm"
                id="passwordConfirm"
                labelClass="text-sm"
              />
            </div>

            <input
              type="submit"
              className="w-full cursor-pointer bg-primary px-4 py-2  text-white"
              value="Register"
            />

            <p className="mt-4 text-sm">
              Already registered?{" "}
              <NavLink className="text-primary" to={NAVIGATION.LOGIN}>
                Login
              </NavLink>{" "}
              now.
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterPage;
