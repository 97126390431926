import { useEffect } from "react";
import Loader from "../../components/Loader";
import { errorToast } from "../../helpers/toast.helper";
import { NAVIGATION } from "../../enums/Navigation.enum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDependency } from "../../contexts/DependencyProvider.context";

const VerifyEmailPage: React.FC = () => {
  const [emailCodeSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { authService } = useDependency();

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    const emailCode = emailCodeSearchParams.get("code");

    if (!emailCode) {
      return navigate(NAVIGATION.REGISTER);
    }

    try {
      await authService.verifyEmail(emailCode);
      navigate(NAVIGATION.LOGIN);
    } catch (e: any) {
      errorToast("Something went wrong");
    }
  };

  return (
    <div className="mx-4 mt-12 rounded-md  border p-4 px-[40px] py-9 md:mx-auto md:w-[404px]">
      <h1 className="text-left text-4xl font-bold">Verify Email</h1>
      <p className="mb-2.5 text-sm">
        We are verifying your email. You will be redirected soon.
      </p>

      <div className="mt-12 flex items-center justify-center">
        <Loader />
      </div>
    </div>
  );
};

export default VerifyEmailPage;
