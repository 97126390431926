import { NavLink, useNavigate } from "react-router-dom";
import { useDependency } from "../contexts/DependencyProvider.context";
import { useEffect } from "react";
import { NAVIGATION } from "../enums/Navigation.enum";

const HomePage = () => {
  const navigate = useNavigate();

  const { userService } = useDependency();

  useEffect(() => {
    setPrefsFromApi();
  }, []);

  const setPrefsFromApi = async () => {
    const userPrefsFromApi = await userService.getUserPreferenceFromAPI();

    userService.storeUserPreference(userPrefsFromApi);

    // Check if thie user prefs are set if set redirect to onboard
    const userPrefs = userService.getUserPreferenceFromStorage();

    if (userPrefs) {
      navigate(NAVIGATION.KYC);
    }
  };

  return (
    <div className="container pt-[3.5rem]">
      <h1 className="heading-1 text-primary">
        Welcome to GrupoNar's <br></br>Closing Coordination Process!
      </h1>

      <div className="text-container mt-4">
        <p>
          We are dedicated to making your property buying or selling experience
          in Mexico as smooth as possible. To help us serve you better, we need
          some information about your transaction.{" "}
        </p>
        <p>
          This process involves a few short steps that will guide you through
          providing necessary details and documents.
        </p>
        <p>
          To begin, please identify whether you are a 'Buyer' or a 'Seller' by
          clicking the appropriate button below. Remember, you can navigate back
          and forth between sections if you need to review or change any
          information.
        </p>
      </div>

      <div className="mt-10 flex space-x-2.5">
        <NavLink
          to={NAVIGATION.ONBOARDING}
          className="border border-grey-100 bg-primary px-10 py-3 font-bold text-site-white transition hover:bg-primary-600"
        >
          Next
        </NavLink>
      </div>
    </div>
  );
};

export default HomePage;
