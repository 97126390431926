import axios from 'axios';
import { inject, injectable } from "tsyringe";
import ApiClient from "../helpers/ApiClient.helper";
import * as apiClientType from "../types/helpers/apiClient.type";
import { IFileService, IGetUploadLinkParam } from "../types/services/fileService.types";
import { IGetFileLinkParam, IGetFileLinkResponse, IGetUploadLinkResponse, IGetUserFilesResponse } from './../types/services/fileService.types';


@injectable()
export default class FileService implements IFileService {
    apiClient: apiClientType.IApiClient;

    constructor(@inject(ApiClient) apiClient: apiClientType.IApiClient) {
        this.apiClient = apiClient;
    }

    async getUserFiles(): Promise<IGetUserFilesResponse> {
        const files = await this.apiClient.get<IGetUserFilesResponse>('/users/files');

        return files;
    }

    async getUploadLink(param: IGetUploadLinkParam): Promise<string> {
        const { url } = await this.apiClient.post<IGetUploadLinkParam, IGetUploadLinkResponse>('/users/upload-link', param);

        return url;
    }

    async uploadFileToLink(uploadLinkParam: IGetUploadLinkParam, file: File): Promise<void> {
        // Get upload link
        const url = await this.getUploadLink(uploadLinkParam);

        // Upload file to link
        await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

    }

    async getFileLink(param: IGetFileLinkParam): Promise<string> {
        const { url } = await this.apiClient.post<IGetFileLinkParam, IGetFileLinkResponse>("/users/download-file", param);

        return url;
    }


}