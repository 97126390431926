import React from 'react'
import "reflect-metadata";
import './styles/index.scss'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify';
import BaseLayout from './layouts/BaseLayout.tsx'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BaseLayout />
    <ToastContainer />
  </React.StrictMode>,
)
