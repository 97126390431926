import { object, string } from "yup";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import InputBox from "../../components/InputBox";
import { NAVIGATION } from "../../enums/Navigation.enum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorToast, successToast } from "../../helpers/toast.helper";
import { useDependency } from "../../contexts/DependencyProvider.context";

const ChangePasswordPage = () => {
    const { authService } = useDependency();
    const navigate = useNavigate();

    const [emailCodeSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");


    useEffect(() => {
        const emailCode =  emailCodeSearchParams.get("code");

        if(!emailCode) {
            return navigate(NAVIGATION.FORGOT_PASSWORD);
        }

        const [email,code] = emailCode.split("|");
        
        setEmail(email);
        setCode(code);
    }, []);

    const changePasswordSchema = object().shape({
      password: string().required("Password is required"),
      confirmPassword: string().required("Confirm password is required")
    });
  
    const handleChangePassword = async (data: { password: string, confirmPassword: string }) => {
      const {password, confirmPassword} = data;

      try {
        await authService.changePassword({
            email,
            code,
            password,
            confirmPassword
        });
        successToast("Password sucessfully changed");
      } catch (e: any) {
        errorToast(e.message);
      }
    };
  
    return (
      <div>
        <div className="mx-4 mt-12 rounded-md  border p-4 px-[40px] py-9 md:mx-auto md:w-[404px]">
          <h1 className="text-left text-4xl font-bold">Change Password</h1>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={changePasswordSchema}
            onSubmit={(values, {resetForm}) => {
                handleChangePassword(values);
                resetForm();
            }}
          >
            {() => (
              <Form>
                <div className="mb-4 mt-3">
                  <InputBox
                    type="password"
                    label="Password"
                    name="password"
                    id={"password"}
                    labelClass="text-sm"
                  />
                </div>

                <div className="mb-4 mt-3">
                  <InputBox
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    id={"confirmPassword"}
                    labelClass="text-sm"
                  />
                </div>
  
                <div className="flex justify-end">
                  <input
                    type="submit"
                    className="w-full cursor-pointer bg-primary px-4 py-2 text-white hover:bg-primary-600"
                    value={"Change Password"}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div></div>
        </div>
      </div>
    );
}


export default ChangePasswordPage;