import { inject, injectable } from "tsyringe";
import ApiClient from "../helpers/ApiClient.helper";
import * as apiClientType from "../types/helpers/apiClient.type";
import { IAuthService, LoginMethodParam, ChangePasswordMethodParam, LoginResponse, RegisterMethodParam, RegisterResponse } from "../types/services/authService.type";
import type ITokenHelper from "../types/helpers/tokenHelper.type";


@injectable()
export default class AuthService implements IAuthService {
    apiClient: apiClientType.IApiClient;
    tokenHelper: ITokenHelper

    constructor(@inject(ApiClient) apiClient: apiClientType.IApiClient, tokenHelper: ITokenHelper) {
        this.apiClient = apiClient;
        this.tokenHelper = tokenHelper;
    }

    logOut(): void {
        return this.tokenHelper.clearToken();
    }

    async requestPasswordChange(email: string): Promise<void> {
        const response = await this.apiClient.post<{ email: string }, void>("/users/auth/request-password-change", { email });

        return response;
    }

    async changePassword(param: ChangePasswordMethodParam): Promise<void> {
        const response = await this.apiClient.post<ChangePasswordMethodParam, void>("/users/auth/change-password", param);

        return response;
    }

    async register(param: RegisterMethodParam): Promise<void> {
        await this.apiClient.post<RegisterMethodParam, RegisterResponse>("/users/auth/register", param);

    }

    async login(param: LoginMethodParam): Promise<LoginResponse> {
        const response = await this.apiClient.post<LoginMethodParam, LoginResponse>("/users/auth/login", param);

        this.saveToken(response.uid);

        return response;
    }

    async verifyEmail(code: string): Promise<void> {
        await this.apiClient.get(`/users/auth/verify?code=${code}`);
    }

    private saveToken = (value: string) => {
        this.tokenHelper.setToken(value);
    }
}