import { string } from "yup";

export interface IDocumentsToSend { id: number, name: string, label: string }



const generalKycDetails = [
  { label: "Nombre Completo <br><span class='text-sm'>First, Middle and last Name, as stated in Passport or birth certificate</span>", name: "full_name", type: "text", validation: string().required("Full name is required") },
  { label: "Lugar de Nacimiento <br><span class='text-sm'>Place of Birth</span>", name: "place_of_birth", type: "text", validation: string().required("Place of Birth is required") },
  { label: "Fecha de Nacimiento <br><span class='text-sm'>Date of Birth</span>", name: "date_of_birth", type: "date", validation: string().required("Date of Birth is required") },
  { label: "Nacionalidad <br><span class='text-sm'>Nationality</span>", name: "nationality", type: "text", validation: string().required("Nationality is required") },
  { label: "Estado Civil (Especificar RÉGIMEN MATRIMONIAL) <br><span class='text-sm'>Marriage Property Status, Please Define if Separate or Joint Property Regime</span>", name: "marital_status", type: "text", validation: string().required("Marital Status is required") },
  { label: "Ocupación (Actividad a la que se dedica) <br><span class='text-sm'>Occupation</span>", name: "occupation", type: "text", validation: string().required("Occupation is required") },
  { label: "Nombre de la empresa (Especificar giro de la Empresa) <br><span class='text-sm'>Name of the Company, Define type of Business</span>", name: "name_of_the_company_and_type", type: "text", validation: string().required("Company name is required") },
  { label: "Teléfono de la Empresa <br><span class='text-sm'>Telephone of the Company</span>", name: "company_telephone", type: "text", validation: string().required("Company telephone is required") },
  { label: "Domicilio de la Empresa <br><span class='text-sm'>Full address of the Company (Workplace address)</span>", name: "company_address", type: "text", validation: string().required("Company address is required") },
  { label: "Domicilio completo (tanto en México como en el extranjero, incluir código postal) <br><span class='text-sm'>Full address en México and Abroad, please include Zip Code</span>", name: "full_address", type: "text", validation: string().required("Full address is required") },
  { label: "Teléfono(s) <br><span class='text-sm'>Telephone</span>", name: "telephone", type: "text", validation: string().required("Telephone is required") },
  { label: "Correo electrónico <br><span class='text-sm'>Email</span>", name: "email", type: "text", validation: string().required("Email is required") },
  { label: "RFC <br><span class='text-sm'>Tax id Number</span>", name: "tax_id", type: "text", validation: string().required("Tax ID is required") },
  { label: "Número de seguro Social <br><span class='text-sm'>Social Security Number</span>", name: "social_security_number", type: "text", validation: string().required("Social Security Number is required") },
  { label: "CURP <br><span class='text-sm'>Population ID</span>", name: "population_id", type: "text", validation: string().required("CURP is required") },
  { label: "Documento con que se identifica <br><span class='text-sm'>Type of document used as ID</span>", name: "type_of_id_document", type: "text", validation: string().required("ID Document type is required") },
  { label: "Forma de pago; <br><span class='text-sm'>FORM OF PAYMENT, please cleary state the: amount, date, form and currency</span>", name: "form_of_payment", type: "text", validation: string().required("Form of Payment details are required") },
  { label: "Requisitos de vendedor(es) y Comprador(es) <br><span class='text-sm'>Seller’s and Buyer´s requirements (where the resource come from and where go for payment?)</span>", name: "seller_and_buyer_requirements", type: "text", validation: string().required("Seller's and Buyer's Requirements are required") },
  { label: "Número de Cuenta <br><span class='text-sm'>Account number</span>", name: "account_number", type: "text", validation: string().required("Account number is required") },
  { label: "Nombre del titular de la Cuenta <br><span class='text-sm'>Beneficiary Name</span>", name: "beneficiary_name", type: "text", validation: string().required("Beneficiary name is required") },
  { label: "Institución Bancaria <br><span class='text-sm'>Bank Name</span>", name: "bank_name", type: "text", validation: string().required("Bank name is required") },
  { label: "¿Existe dueño beneficiario? <br><span class='text-sm'>Will be using the property?, Name of Beneficiary Owner</span>", name: "beneficiary_owner", type: "text", validation: string().required("Beneficiary owner is required") },
  { label: "¿Existe beneficiario controlador? <br><span class='text-sm'>Who pays or receives the resources of the operation? Name of controlling beneficiary as the case may be</span>", name: "controlling_beneficiary", type: "text", validation: string().required("Controlling beneficiary is required") },
  { label: "Beneficiario(s) en caso de fallecimiento <br><span class='text-sm'>Beneficiary(ies) in the event of death (specify family relationship and percentage)</span>", name: "death_beneficiary", type: "text", validation: string().required("Death beneficiary is required") },
  { label: "¿Usted o alguno de los participantes en la operación es o fue en el año anterior, persona políticamente expuesta (PEP) o es familiar de alguna persona políticamente expuesta? <br><span class='text-sm'>You or any of the parts in the operation is or was has been in the previous year, a politically exposed person (PEP) or is a family member of a politically exposed person?</span>", name: "politically_exposed_person", type: "text", validation: string().required("Politically Exposed Person status is required") }
]



export const documentsFieldData = {
  seller: {
    individual: {
      foreign: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 1, name: "Passport", label: "Passport" },
          { id: 2, name: "ProofOfAddress", label: "Proof of Address" },
          { id: 3, name: "TaxIdSocialSecurityCard", label: "Tax Id/ Social Security Card" },
          { id: 4, name: "BirthCertificate", label: "Birth Certificate" },
          { id: 5, name: "MarriageCertificate", label: "Marriage Certificate (If Applicable)" },
          { id: 6, name: "DriverLicense", label: "Driver's License" },
          { id: 7, name: "MigratoryStatusDocument", label: "Migratory Status (document)" },
          { id: 8, name: "DeedPropertyTaxReceipt", label: "Deed and Property Tax Receipt" },
          { id: 9, name: "LastAnnualPaymentBankTrustFees", label: "Last Annual Payment of Bank Trust Fees" }
        ]
      },
      mexican: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 10, name: "EscrituraPública", label: "Escritura Pública" },
          { id: 11, name: "Predial", label: "Predial" },
          { id: 12, name: "CURP", label: "CURP" },
          { id: 13, name: "ConstanciadeSituaciónFiscal", label: "Constancia de Situación Fiscal" },
          { id: 14, name: "Comprobante de domicilio", label: "Comprobante de domicilio" },
          { id: 15, name: "Identificación", label: "Identificación" },
          { id: 16, name: "Actadenacimiento", label: "Acta de nacimiento" },
          { id: 17, name: "Actadematrimonio(siaplica)", label: "Acta de matrimonio (si aplica)" }
        ]
      }
    },
    corporation: {
      foreign: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 1, name: "Passport", label: "Passport" },
          { id: 2, name: "ProofOfAddress", label: "Proof of Address" },
          { id: 3, name: "TaxIdSocialSecurityCard", label: "Tax Id/ Social Security Card" },
          { id: 4, name: "BirthCertificate", label: "Birth Certificate" },
          { id: 5, name: "MarriageCertificate", label: "Marriage Certificate (If Applicable)" },
          { id: 6, name: "DriverLicense", label: "Driver's License" },
          { id: 7, name: "MigratoryStatusDocument", label: "Migratory Status (document)" },
          { id: 8, name: "DeedPropertyTaxReceipt", label: "Deed and Property Tax Receipt" },
          { id: 9, name: "LastAnnualPaymentBankTrustFees", label: "Last Annual Payment of Bank Trust Fees" }
        ]
      },
      mexican: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 18, name: "ACTACONSTITUTIVA", label: "ACTA CONSTITUTIVA" },
          { id: 19, name: "PODERDELREPRESENTANTELEGAL", label: "PODER DEL REPRESENTANTE LEGAL" },
          { id: 12, name: "CURP", label: "CURP" },
          { id: 13, name: "ConstanciadeSituaciónFiscal", label: "Constancia de Situación Fiscal" },
          { id: 14, name: "Comprobante de domicilio", label: "Comprobante de domicilio" },
          { id: 15, name: "Identificación", label: "Identificación" },
          { id: 16, name: "Actadenacimiento", label: "Acta de nacimiento" },
          { id: 17, name: "Actadematrimonio(siaplica)", label: "Acta de matrimonio (si aplica)" }
        ]
      }
    }
  },
  buyer: {
    individual: {
      foreign: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 1, name: "Passport", label: "Passport" },
          { id: 2, name: "ProofOfAddress", label: "Proof of Address" },
          { id: 3, name: "TaxIdSocialSecurityCard", label: "Tax Id/ Social Security Card" },
          { id: 4, name: "BirthCertificate", label: "Birth Certificate" },
          { id: 5, name: "MarriageCertificate", label: "Marriage Certificate (If Applicable)" },
          { id: 6, name: "DriverLicense", label: "Driver's License" },
          { id: 7, name: "MigratoryStatusDocument", label: "Migratory Status (document)" },
          { id: 35, name: "PassportAndDriverLicenseOfSubstituteBeneficaries", label: "Passport and Driver License of Substitute Beneficiaries" }
        ]
      },
      mexican: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 12, name: "CURP", label: "CURP" },
          { id: 13, name: "ConstanciadeSituaciónFiscal", label: "Constancia de Situación Fiscal" },
          { id: 14, name: "Comprobante de domicilio", label: "Comprobante de domicilio" },
          { id: 15, name: "Identificación", label: "Identificación" },
          { id: 16, name: "Actadenacimiento", label: "Acta de nacimiento" },
          { id: 17, name: "Actadematrimonio(siaplica)", label: "Acta de matrimonio (si aplica)" }
        ]
      }
    },
    corporation: {
      foreign: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 1, name: "Passport", label: "Passport" },
          { id: 2, name: "ProofOfAddress", label: "Proof of Address" },
          { id: 3, name: "TaxIdSocialSecurityCard", label: "Tax Id/ Social Security Card" },
          { id: 4, name: "BirthCertificate", label: "Birth Certificate" },
          { id: 17, name: "MarriageCertificate", label: "Marriage Certificate (If Applicable)" },
          { id: 6, name: "DriverLicense", label: "Driver's License" },
          { id: 7, name: "MigratoryStatusDocument", label: "Migratory Status (document)" },
          { id: 35, name: "PassportAndDriverLicenseOfSubstituteBeneficaries", label: "Passport and Driver License of Substitute Beneficiaries" }
        ]
      },
      mexican: {
        fieldsToFill: generalKycDetails,
        documentsToUpload: [
          { id: 18, name: "ACTACONSTITUTIVA", label: "ACTA CONSTITUTIVA" },
          { id: 19, name: "PODERDELREPRESENTANTELEGAL", label: "PODER DEL REPRESENTANTE LEGAL" },
          { id: 12, name: "CURP", label: "CURP" },
          { id: 13, name: "ConstanciadeSituaciónFiscal", label: "Constancia de Situación Fiscal" },
          { id: 14, name: "Comprobante de domicilio", label: "Comprobante de domicilio" },
          { id: 15, name: "Identificación", label: "Identificación" },
          { id: 16, name: "Actadenacimiento", label: "Acta de nacimiento" },
          { id: 17, name: "Actadematrimonio(siaplica)", label: "Acta de matrimonio (si aplica)" }
        ]
      }
    }
  },
  llc: {
    fromTheLlc: {
      label: "From the LLC",
      documentsToUpload:
        [
          { id: 20, name: "ArticlesOfIncorporation", label: "Articles of Incorporation (notarized and Apostilled)" },
          { id: 21, name: "OperationAgreement", label: "Operation Agreement (notarized and Apostilled)" },
          { id: 22, name: "GoodStandingCertificate", label: "Good Standing Certificate (Apostilled)" },
          { id: 23, name: "LLCManagerAgentFaculties", label: "LLC's manager's or agent's faculties (notarized, Apostilled). This document is a one-page affidavit by the incorporator or attorney referencing that you as a member have all powers to represent the LLC to acquire property rights in Mexico through a Mexican trust." },
          { id: 24, name: "LLCProofOfAddress", label: "Copy of LLC's proof of address (such as any recent electricity, gas or telephone bill)" },
          { id: 25, name: "IRSLetter", label: "IRS letter" }
        ]
    },
    fromLlcMembers: {
      label: "From LLC's Members",
      documentsToUpload:
        [
          { id: 26, name: "ProofOfAddress", label: "Proof of address with a recent date (any utility bill)" },
          { id: 27, name: "Occupation", label: "Occupation" },
          { id: 28, name: "SSN", label: "SSN" },
          { id: 29, name: "Telephone", label: "Telephone number" }
        ]
    },
    fromLlcManager: {
      label: "From LLC's Manager (Legal Representative)",
      documentsToUpload:
        [
          { id: 30, name: "PassportScan", label: "Color Scan copy of passports (full-framed without fingers)" },
          { id: 31, name: "ProofOfAddress", label: "Proof of address with a recent date (any utility bill)" },
          { id: 32, name: "Occupation", label: "Occupation" },
          { id: 33, name: "SSN", label: "SSN" },
          { id: 34, name: "Telephone", label: "Telephone number" }
        ]
    }

  }
}